<template>
  <!--Navbar Start-->
  <nav
    class="navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark main-navbar"
    id="navbar"
    :class="{ 'navbar-light': navbarcolor === true }"
  >
    <div class="container-fluid">
      <!-- LOGO -->
      <a class="navbar-bran logo ml-lg-5 pl-lg-5 logo_img" href="/">
        <img
          src="@/assets/images/Logo1.png"
          alt
          class="logo-dark"
          height="48"
        />
        <div class="logo-list">
          <span class="logo-title">O‘zbekiston Respublikasi</span>
          <span class="logo-title">Madaniyat vazirligi</span>
          <!-- <span class="logo-title">IJODKORLAR.UZ</span>
          <span class="logo-text">Ijodkorlar yagona portali</span> -->
        </div>
      </a>
      <button
        class="navbar-toggler"
        type="button"
        @click="toggleMenu()"
        aria-label="Toggle navigation"
      >
        <i class="mdi mdi-menu"></i>
      </button>
      <div class="collapse navbar-collapse" id="navbarCollapse">
        <ul
          class="navbar-nav mx-auto navbar-center"
          id="mySidenav"
        >
          <!-- <li class="nav-item">
            {{ $t("sportERP") }}
          </li> -->
          <li class="nav-item">
            <a  @click="$router.push({ name: 'HomePage' })" :class="($route.name == 'HomePage' || $route.name == 'Home') ? 'active' : ''"  href="javascript: void(0);" class="nav-link">Bosh oyna</a>
          </li>
          <li class="nav-item">
            <a
            @click="$router.push({ name: 'CountryReward' })"
              href="javascript: void(0);"
              class="nav-link"
              :class="$route.name == 'CountryReward' ? 'active' : ''"
            >Davlat mukofotlari</a>
          </li>
          <li class="nav-item">
            <a
            @click="$router.push({ name: 'RewardWinners' })"
              href="javascript: void(0);"
              class="nav-link"
              :class="$route.name == 'RewardWinners' ? 'active' : ''"
            >Davlat mukofoti sovrindorlari</a>
          </li>
          <li class="nav-item">
            <a
              @click="$router.push({ name: 'Creators' })"
              href="javascript: void(0);"
              class="nav-link"
              :class="($route.name == 'Creators' || $route.name == 'CreatorsView') ? 'active' : ''"
            >Ijodkorlar</a>
          </li>
        </ul>
        <ul class="navbar-nav navbar-center mr-5">
          <li class="nav-item mr-5">
            <!-- <b-button variant="primary" class="login-btn" @click="enterToLogin">
              ID orqali kirish
            </b-button> -->
            <a href="http://profile.ijodkorlar.uz/" variant="primary" class="login-btn">
              ID orqali kirish
            </a>
            <!-- <button type="submit" class="btn btn-outline-primary btn-sm mr-2" @click="isOpenSidebar = true"> {{ $t('lang') }}  </button> -->
            <!-- <b-dropdown
              id="dropdown-1"
              variant="link"
              no-caret
              toggle-class="text-decoration-none"
              size="sm"
              class="mr-2"
            >
              <template #button-content>
                <span class="text-primary"
                  >{{ $t("lang") }}
                  <b-icon-caret-down scale="0.8"></b-icon-caret-down
                ></span>
              </template>
              <b-dropdown-item
                :active="lang == 'uz_latn'"
                @click="ChangeLang('uz_latn')"
              >
                <b>O'zbekcha</b>
              </b-dropdown-item>
              <b-dropdown-item
                :active="lang == 'uz_cyrl'"
                @click="ChangeLang('uz_cyrl')"
                >Ўзбекча</b-dropdown-item
              >
              <b-dropdown-item :active="lang == 'ru'" @click="ChangeLang('ru')"
                >Русский</b-dropdown-item
              >
            </b-dropdown> -->
          </li>
        </ul>
      </div>
    </div>
    <b-sidebar
      no-header
      @shown="GetNotifyInfo"
      width="400px"
      shadow
      right
      v-model="$store.state.isOpenNotification"
      bg-variant="white"
    >
      <div style="width  :100%;height:100%;">
        <div
          class="container-fluid w-100"
          style="width:100% !important;position:relative;overflow-y:auto;"
        >
          <b-row :class="isToken ? 'w-100 mb-5' : 'w-100'">
            <b-col class="text-right close-icon">
              <!-- <b-img @click="isOpenSidebar = false" src="/images/design/close.svg" /> -->
              <b-icon-x
                scale="2.5"
                style="cursor:pointer;z-index:9"
                @click="$store.state.isOpenNotification = false"
              ></b-icon-x>
            </b-col>
          </b-row>
          <b-row class="w-100">
            <b-col>
              <h4>{{ $t("notification") }}</h4>
            </b-col>
          </b-row>
          <b-row class="w-100">
            <b-col>
              <div
                class="my-custom-card mt-3"
                v-for="(item, index) in NotifyList"
                :key="index"
              >
                <div class="d-flex align-items-center">
                  <small style="margin-right:5px">
                    {{ item.dateofcreated }}
                  </small>
                  -
                  <b-badge class="ml-2" variant="success">
                    {{ item.title }}
                  </b-badge>
                </div>

                <p @click="ReadMessage(item)">
                  {{ item.webtext }}
                </p>
              </div>
              <div class="my-custom-card mt-3" v-if="NotifyList.length == 0">
                <p
                  class="d-flex align-items-center justify-content-center pb-0 mb-0"
                >
                  {{ $t("nothinghere") }}
                </p>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-sidebar>
    <b-sidebar
      no-header
      width="400px"
      shadow
      right
      v-model="$store.state.isOpenSidebar"
      bg-variant="white"
    >
      <div style="width  :100%;height:100%;">
        <div
          class="container-fluid w-100"
          style="width:100% !important;position:relative;overflow-y:auto;"
        >
          <b-row :class="isToken ? 'w-100 mb-5' : 'w-100'">
            <b-col class="text-right close-icon">
              <!-- <b-img @click="isOpenSidebar = false" src="/images/design/close.svg" /> -->
              <b-icon-x
                scale="2.5"
                style="cursor:pointer;z-index:9"
                @click="$store.state.isOpenSidebar = false"
              ></b-icon-x>
            </b-col>
          </b-row>
          <b-row v-if="!isToken" class="w-100">
            <b-col
              class="d-flex align-items-center my-sidebar-body"
              style="position:relative;overflow-y:auto;margin-bottom:60px"
            >
              <div class="w-100">
                <b-row>
                  <b-col>
                    <p class="text-sign text-primary-dark">{{ $t("enter") }}</p>
                    <p class="text-info-sign text-primary-dark">
                      {{ $t("usephonenumber") }}
                    </p>
                  </b-col>
                </b-row>
                <b-row style="margin-bottom:16px">
                  <b-col sm="12">
                    <custom-input
                      :label="$t('phonenumber')"
                      @keyup="ChangePhone"
                      v-mask="'+998-##-###-##-##'"
                      v-model="Account.phonenumber"
                      placeholder="+998 _ _  _ _ _  _ _  _ _"
                    ></custom-input>
                  </b-col>
                </b-row>
                <b-row
                  v-if="
                    filter.isPassword === null && filter.isSmsCode === false
                  "
                >
                  <b-col>
                    <button
                      type="submit"
                      class="btn btn-primary btn-block"
                      @click="isCheckAccount"
                    >
                      <b-spinner
                        v-if="checkAccountLoading"
                        small
                        style="margin-right:8px"
                      ></b-spinner>
                      {{ $t("register") }}
                    </button>
                  </b-col>
                </b-row>
                <b-row
                  v-if="filter.isPassword === true"
                  style="margin-bottom:16px"
                >
                  <b-col sm="12">
                    <custom-input
                      type="password"
                      v-model="Account.password"
                      :label="$t('password')"
                      placeholder="****"
                    ></custom-input>
                  </b-col>
                  <b-col sm="12" class="mt-2  d-flex justify-content-end">
                    <a
                      @click="OpenForgotPass"
                      class="cursor-pointer"
                      style="color : #003D45;text-decoration:none;cursor:pointer"
                    >
                      {{ $t("forgotpassword") }}
                    </a>
                  </b-col>
                </b-row>
                <b-row v-if="filter.isSmsCode" style="margin-bottom:16px">
                  <b-col sm="12"> </b-col>
                  <b-col sm="12">
                    <custom-input
                      v-mask="'####'"
                      v-model="Account.smscode"
                      :label="$t('smskod')"
                      placeholder="1234"
                    ></custom-input>
                  </b-col>
                </b-row>
                <b-row v-if="filter.isPassword === true">
                  <b-col>
                    <!-- <custom-button @click.native="SignIn" block> <b-spinner v-if="SignLoading" small style="margin-right:8px"></b-spinner> {{ $t('auth') }} </custom-button> -->
                    <button
                      type="submit"
                      class="btn btn-primary block"
                      @click="SignIn"
                    >
                      <b-spinner
                        v-if="SignLoading"
                        small
                        style="margin-right:8px"
                      ></b-spinner>
                      {{ $t("auth") }}
                    </button>
                  </b-col>
                </b-row>
                <b-row v-if="filter.isPassword === false">
                  <b-col sm="12" style="margin-bottom:16px" class="text-center">
                    <span class="text-center text-danger">
                      {{ $t("goregister") }}
                    </span>
                  </b-col>
                  <b-col sm="12" style="margin-bottom:16px">
                    <custom-input
                      type="password"
                      v-model="Account.password"
                      :label="$t('password')"
                      placeholder="******"
                    ></custom-input>
                  </b-col>
                  <b-col sm="12" style="margin-bottom:16px">
                    <custom-input
                      type="password"
                      v-model="Account.passwordconfirm"
                      :label="$t('confirmpassword')"
                      placeholder="******"
                    ></custom-input>
                  </b-col>
                  <b-col
                    sm="12"
                    v-if="filter.isSmsForRegister"
                    style="margin-bottom:16px"
                    class="text-center"
                  >
                    <span class="text-center text-danger">
                      {{
                        $t("lang") == "Ру"
                          ? $t("entersmscodesentnumber") +
                            " " +
                            Account.phonenumber
                          : Account.phonenumber +
                            " " +
                            $t("entersmscodesentnumber")
                      }}
                    </span>
                  </b-col>
                  <b-col
                    sm="12"
                    v-if="filter.isSmsForRegister"
                    style="margin-bottom:16px"
                  >
                    <custom-input
                      v-mask="'####'"
                      v-model="Account.smscode"
                      :label="$t('smskod')"
                      placeholder="1234"
                    ></custom-input>
                  </b-col>
                  <b-col sm="12" v-if="!filter.isSms">
                    <!-- <custom-button @click.native="GiveSmsCode" block> <b-spinner v-if="SignLoading" small style="margin-right:8px"></b-spinner> {{ $t('Smskodloish') }} </custom-button> -->
                    <button
                      type="submit"
                      class="btn btn-primary btn-block"
                      @click="GiveSmsCode"
                    >
                      <b-spinner
                        v-if="SignLoading"
                        small
                        style="margin-right:8px"
                      ></b-spinner>
                      {{ $t("Smskodloish") }}
                    </button>
                  </b-col>
                  <b-col sm="12" v-if="filter.isSmsForRegister">
                    <!-- <custom-button @click.native="SendSmsCode" block> <b-spinner v-if="SignLoading" small style="margin-right:8px"></b-spinner> {{ $t('registration') }} </custom-button> -->
                    <button
                      type="submit"
                      class="btn btn-primary btn-block"
                      @click="SendSmsCode"
                    >
                      <b-spinner
                        v-if="SignLoading"
                        small
                        style="margin-right:8px"
                      ></b-spinner>
                      {{ $t("registration") }}
                    </button>
                  </b-col>
                </b-row>
              </div>
            </b-col>
          </b-row>
          <b-row v-if="isToken" class="w-100 mt-3">
            <b-col cols="12 ">
              <span class="d-block" style="font-size: 28px; margin-left: 15px">
                {{ getUserName }}
              </span>
              <div
                class="mt-5 d-flex align-items-center"
                style="cursor:pointer"
                @click="GotoCabinet"
              >
                <!-- <b-img style="margin-left: 15px;margin-right: 5px;  display: inline;cursor:pointer" src="/images/school/acc-login.svg"></b-img> -->
                <b-icon-house
                  scale="1.2"
                  style="margin-left: 15px;margin-right: 5px;  display: inline;cursor:pointer"
                ></b-icon-house>
                <span style="margin-left: 8px"> {{ $t("mycabinet") }} </span>
              </div>
              <div
                class="d-flex mt-3 align-items-center"
                style="cursor:pointer"
                @click="GotoApplication"
              >
                <!-- <b-img style="margin-left: 15px;margin-right: 5px;  display: inline;cursor:pointer" src="/images/school/acc-login.svg"></b-img> -->
                <b-icon-file-earmark
                  scale="1.2"
                  style="margin-left: 15px;margin-right: 5px;  display: inline;cursor:pointer"
                ></b-icon-file-earmark>
                <span style="margin-left: 8px">
                  {{ $t("myapplication") }}
                </span>
              </div>
              <div
                class="mt-3 d-flex align-items-center"
                v-if="isChooseChild == true"
                style="cursor:pointer"
                @click="GoChooseChild"
              >
                <b-icon-person-plus
                  scale="1.2"
                  style="margin-left: 15px;margin-right: 5px;  display: inline;cursor:pointer"
                ></b-icon-person-plus>
                <span style="margin-left: 8px"> {{ $t("FilterChild") }} </span>
              </div>
              <div
                class="mt-3 d-flex align-items-center"
                v-if="isCourtApplication == true"
                style="cursor:pointer"
                @click="GoCourtApplication"
              >
                <b-icon-file-earmark-medical
                  scale="1.2"
                  style="margin-left: 15px;margin-right: 5px;  display: inline;cursor:pointer"
                ></b-icon-file-earmark-medical>
                <span style="margin-left: 8px">
                  {{ $t("CourtApplication") }}
                </span>
              </div>
              <!-- 
                            <div class="mt-3 d-flex align-items-center" style="cursor:pointer" @click="GoMyChild">
                                <child-icon width="35px" style="margin-left: 8px;margin-right: 0px; display: inline;cursor:pointer" active variant="success-light"></child-icon>
                                <span style="margin-left: 8px">  {{ $t('mykids') }}  </span>
                            </div>
                            <div class="mt-3 d-flex align-items-center" style="cursor:pointer">
                                <b-img style="margin-left: 15px; margin-right: 5px; display: inline;cursor:pointer" src="/images/school/support.svg"></b-img>
                                <span style="margin-left: 8px"> {{ $t('support') }} </span>
                            </div>
                             -->
            </b-col>
            <b-col cols="12" class="mt-2">
              <hr
                style="width:80%;margin-left:auto;megin-right:auto;display:block"
              />
            </b-col>
            <b-col cols="12">
              <div style="cursor:pointer" @click="Logout">
                <!-- <b-img style="margin-left: 15px; display: inline;" src="/images/school/logout.svg"></b-img> -->
                <b-icon-lock
                  style="margin-left: 15px; display: inline;"
                  scale="1.2"
                ></b-icon-lock>
                <span style="margin-left: 8px"> {{ $t("logout") }} </span>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-sidebar>
    <b-modal
      v-model="ForgotPasswordModal"
      size="lg"
      hide-footer
      hide-header
      no-close-on-backdrop
      centered
    >
      <b-row>
        <b-col class="d-flex justify-content-between align-items-center">
          <span> {{ $t("restore") }} </span>
          <!-- <img class="cursor-pointer"  src="/images/design/fill-close.svg" alt=""> -->
          <b-icon-x
            scale="2.5"
            style="cursor:pointer;z-index:9"
            @click="ForgotPasswordModal = false"
          ></b-icon-x>
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col>
          <custom-input
            disabled
            :label="$t('phonenumber')"
            v-mask="'+998-##-###-##-##'"
            v-model="Restore.username"
            placeholder="+998 _ _  _ _ _  _ _  _ _"
          ></custom-input>
        </b-col>
      </b-row>
      <div>
        <b-row>
          <b-col>
            <p class="p-2">
              {{ $t("smssent", { phonenumber: Restore.phonenumber }) }}
            </p>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col>
            <custom-input
              type="password"
              :placeholder="$t('*******')"
              v-model="Restore.newpassword"
              :label="$t('password')"
            ></custom-input>
          </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-col>
            <custom-input
              type="password"
              :placeholder="$t('*******')"
              v-model="Restore.confirmedpassword"
              :label="$t('confirmpassword')"
            ></custom-input>
          </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-col>
            <custom-input
              v-model="Restore.smscode"
              :placeholder="$t('0000')"
              :label="$t('smskod')"
            ></custom-input>
          </b-col>
        </b-row>
      </div>
      <b-row class="mt-3">
        <b-col>
          <!-- <custom-button v-if="!Restore.isRestore" @click.native="RestorePassword" block> <b-spinner v-if="RestoreLoading" small style="margin-right:8px"></b-spinner> {{ $t('Smskodloish') }} </custom-button> -->
          <b-button
            variant="success"
            pill
            v-if="Restore.isRestore"
            @click.native="RestorePasswordConfirm"
            block
          >
            <b-spinner
              v-if="RestoreLoading"
              small
              style="margin-right:8px"
            ></b-spinner>
            {{ $t("restore") }}
          </b-button>
        </b-col>
      </b-row>
      <b-row> </b-row>
    </b-modal>
  </nav>
  <!-- Navbar End -->
</template>
<script>
import {
  FacebookIcon,
  TwitterIcon,
  InstagramIcon,
  LinkedinIcon,
  ArrowRightCircleIcon,
} from "vue-feather-icons";
import CustomInput from "@/views/components/CustomInput";
import {
  BIconX,
  BIconCaretDown,
  BIconLock,
  BIconFileEarmark,
  BIconPersonPlus,
  BIconHouse,
  BIconBell,
  BIconFileEarmarkMedical,
} from "bootstrap-vue";
import AccountService from "../services/account.service";
import WebNotifyService from "../services/webnotify.service";
export default {
  props: {
    navbarcolor: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    FacebookIcon,
    TwitterIcon,
    InstagramIcon,
    LinkedinIcon,
    ArrowRightCircleIcon,
    CustomInput,
    BIconX,
    BIconCaretDown,
    BIconLock,
    BIconFileEarmark,
    BIconPersonPlus,
    BIconHouse,
    BIconBell,
    BIconFileEarmarkMedical,
  },
  data() {
    return {
      Account: {
        phonenumber: "",
        password: "",
        passwordconfirm: "",
        smscode: "",
      },
      isToken: false,
      filter: {
        isPassword: null,
        isSmsCode: false,
        isSmsForRegister: false,
        isSms: false,
        admissiontypeid: 0,
      },
      checkAccountLoading: false,
      SignLoading: false,
      lang: localStorage.getItem("locale") || "uz_latn",
      NotifyList: [],
      ForgotPasswordModal: false,
      Restore: {
        username: "",
        isRestore: false,
        phonenumber: "",
        smscode: "",
        newpassword: "",
        confirmedpassword: "",
      },
    };
  },
  created() {
    this.isToken = localStorage.getItem("user_info");
  },
  computed: {
    getUserName() {
      return localStorage.getItem("user_info")
        ? JSON.parse(localStorage.getItem("user_info")).UserDisplayName
        : "";
    },
    isChooseChild() {
      return localStorage.getItem("user_info")
        ? JSON.parse(localStorage.getItem("user_info")).CanSearchChild
        : "";
    },
    isCourtApplication() {
      return localStorage.getItem("user_info")
        ? JSON.parse(localStorage.getItem("user_info")).CanViewCourtApplication
        : "";
    },
  },
  mounted: () => {
    window.onscroll = function() {
      onwindowScroll();
    };
    var navbar = document.getElementById("navbar");
    function onwindowScroll() {
      if (
        document.body.scrollTop > 50 ||
        document.documentElement.scrollTop > 50
      ) {
        navbar.classList.add("nav-sticky");
      } else {
        navbar.classList.remove("nav-sticky");
      }
    }
  },
  methods: {
    OpenForgotPass() {
      this.ForgotPasswordModal = true;
      (this.Restore = {
        username: "",
        isRestore: false,
        phonenumber: "",
        smscode: "",
        newpassword: "",
        confirmedpassword: "",
      }),
        (this.Restore.username = this.Account.phonenumber);
      this.RestorePassword();
    },
    GetNotifyInfo() {
      WebNotifyService.GetUnReadMessages().then((res) => {
        this.NotifyList = res.data.result;
      });
    },
    GotoCabinet() {
      this.$router.push({ name: "Cabinet" });
      this.$store.state.isOpenSidebar = false;
    },
    GoChooseChild() {
      this.$router.push({ name: "ChooseChild" });
      this.$store.state.isOpenSidebar = false;
    },
    GoCourtApplication() {
      this.$router.push({ name: "CourtApplication" });
      this.$store.state.isOpenSidebar = false;
    },
    GotoApplication() {
      this.$router.push({ name: "Application" });
      this.$store.state.isOpenSidebar = false;
    },
    enterToLogin() {
      this.$router.push('http://profile.ijodkorlar.uz/');
    },
    Logout() {
      AccountService.Logout().then((res) => {
        localStorage.clear();
        if (this.$route.name !== "Home") {
          this.$router.push("/");
        } else {
          window.location.reload();
        }
      });
      this.$store.state.isOpenSidebar = false;
    },
    makeToast(message, type) {
      this.$toast.open({
        message: message,
        type: type,
        duration: 5000,
        dismissible: true,
      });
    },
    toggleMenu() {
      document.getElementById("navbarCollapse").classList.toggle("show");
    },
    ChangePhone() {
      this.Account.password = "";
      this.Account.passwordconfirm = "";
      this.Account.smscode = "";
      this.filter.isPassword = null;
      this.filter.isSmsCode = false;
      this.filter.isSmsForRegister = false;
      this.filter.isSms = false;
    },
    SignIn() {
      this.SignLoading = true;
      if (!this.filter.isSmsCode) {
        this.Account.username = this.Account.phonenumber;
        AccountService.SignIn(this.Account)
          .then((res) => {
            this.SignLoading = false;
            if (!res.data.trusteddevice) {
              this.filter.isSmsCode = true;
            } else {
              localStorage.setItem(
                "user_info",
                JSON.stringify(res.data.userinfo)
              );
              this.$router.replace("/cabinet", () => {});
              this.$store.state.isOpenSidebar = false;
            }

            // this.filter.isSmsCode = res.data.trusteddevice
          })
          .catch((error) => {
            this.SignLoading = false;
            this.makeToast(error.response.data.error, "error");
          });
      } else {
        AccountService.SignInTwoFactor(this.Account)
          .then((res) => {
            this.SignLoading = false;
            localStorage.setItem(
              "user_info",
              JSON.stringify(res.data.userinfo)
            );
            this.$router.push({ name: "Cabinet" });
            this.$store.state.isOpenSidebar = false;
          })
          .catch((error) => {
            this.makeToast(error.response.data.error, "error");
            this.SignLoading = false;
          });
      }
    },
    isCheckAccount() {
      if (this.Account.phonenumber.length === 17) {
        this.checkAccountLoading = true;
        AccountService.IsCheckAccount(this.Account)
          .then((res) => {
            this.checkAccountLoading = false;
            if (res.data.success) {
              this.filter.isPassword = true;
            }
            if (!res.data.success) {
              this.filter.isPassword = false;
            }
          })
          .catch((error) => {
            this.checkAccountLoading = false;
            this.makeToast(error.response.data.error, "error");
          });
      }
    },
    GiveSmsCode() {
      this.SignLoading = true;
      AccountService.SendSMSCode(this.Account)
        .then((res) => {
          this.SignLoading = false;
          if (res.data.success) {
            this.filter.isSmsForRegister = true;
            this.filter.isSms = true;
          }
        })
        .catch((error) => {
          this.SignLoading = false;
          this.makeToast(error.response.data.error, "error");
        });
    },
    SendSmsCode() {
      this.SignLoading = true;
      if (!this.filter.isSmsForRegister) {
        AccountService.SendSMSCode(this.Account)
          .then((res) => {
            this.SignLoading = false;
            if (res.data.success) {
              this.filter.isSmsForRegister = true;
              this.filter.isSms = true;
            }
          })
          .catch((error) => {
            this.SignLoading = false;
            this.makeToast(error.response.data.error, "error");
          });
      } else {
        if (this.Account.smscode.length === 4) {
          AccountService.CheckSMSCode({
            smscode: this.Account.smscode,
            phonenumber: this.Account.phonenumber,
          })
            .then((res) => {
              this.$router.push({ name: "Oferta" });

              this.$store.state.number = this.Account.phonenumber;
              //   this.$router.push({ name: "Oferta"},);
              localStorage.setItem("info", res.data);
              // this.$store.state.isOpenSidebar = false
              this.SignLoading = false;
            })
            .catch((error) => {
              this.makeToast(error.response.data.error, "error");
              this.SignLoading = false;
            });
        } else {
          this.SignLoading = false;
          this.makeToast(this.$t("entersmscode"), "error");
        }
      }
    },
    ChangeLang(lang) {
      this.lang = lang;
      localStorage.setItem("locale", lang);
      window.location.reload();
    },
    clear() {
      this.Account = {
        phonenumber: "",
        password: "",
        passwordconfirm: "",
        smscode: "",
      };
      this.filter = {
        isPassword: null,
        isSmsCode: false,
        isSmsForRegister: false,
        isSms: false,
      };
    },
    GetNotifyCount() {
      WebNotifyService.GetUnReadMessagesCount().then((res) => {
        this.notifycount = res.data.result;
      });
    },
    RestorePasswordConfirm() {
      this.RestoreLoading = true;
      AccountService.RestorePasswordConfirm(this.Restore)
        .then((res) => {
          this.RestoreLoading = false;
          this.ForgotPasswordModal = false;
          this.makeToast(this.$t("PasswordChangedSuccess"), "success");
        })
        .catch((error) => {
          this.RestoreLoading = false;
          this.makeToast(error.response.data.error, "error");
        });
    },
    RestorePassword() {
      this.RestoreLoading = true;
      AccountService.RestorePassword(this.Restore)
        .then((res) => {
          this.Restore.isRestore = true;
          this.Restore.phonenumber = res.data.phoneNumber;
          this.RestoreLoading = false;
        })
        .catch((error) => {
          this.makeToast(error.response.data.error, "error");
          this.RestoreLoading = false;
        });
    },
  },
  beforeRouteUpdate(to, from, next) {
    this.$store.state.isOpenSidebar = false;
    this.isToken = localStorage.getItem("user_info");
    next();
    alert(this.isToken);
  },
  watch: {
    "$store.state.isOpenSidebar": {
      handler(newValue) {
        this.clear();
      },
    },
    $route(to, from) {
      this.isToken = localStorage.getItem("user_info");
      if (this.isToken) {
        this.GetNotifyCount();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.main-navbar {
  .nav-link {
    color: #fff;
  }
  .navbar-custom .navbar-nav li.active a, .navbar-custom .navbar-nav li a:hover, .navbar-custom .navbar-nav li a:active {
    color: #fff !important;
    opacity: .9;
  }
  .nav-link.active {
    color: #fff;
    border-bottom: spx solid #fff;
  }
}
.logo_img {
  display: flex;
  align-items: center;
  color: #fff;
  .logo-list {
    display: grid;
    margin-left: 15px;
  }
  .logo-title {
    font-size: 17px !important;
    text-transform: uppercase;
    margin: 0;
    padding: 0;
    line-height: 1;
    color: #fff;
  }
  .logo-texst {
    font-size: 10px !important;
    margin: 0;
    padding: 0;
    line-height: 1;
    color: #fff !important;
  }
}
.navbar-custom {
  .login-btn, .btn.btn-primary {
    background-color: transparent !important;
    border: 1.5px solid #407EE1 !important;
    border-radius: 10px !important;
    min-width: 150px;
    color: white;
    padding: 10px 20px;
  }
  .login-btn:hover, .btn.btn-primary:hover {
    background-color: #407EE1 !important;
  }
}
</style>
